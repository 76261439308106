<script setup>
import { computed } from 'vue';

const props = defineProps({
  form: { type: Object, required: true },
});

const orderedSections = computed(
  () => Object.values(props.form.sections)
    .sort((a, b) => a.order - b.order),
);

// Function to sort fields within a section based on the 'order' key
function filteredSortedFields(fields) {
  return Object.values(fields)
    .filter((field) => field.value)
    .sort((a, b) => a.order - b.order);
}
</script>

<template>
  <ol class="flex flex-col gap-y-10">
    <ol
      v-for="section in orderedSections"
      :key="section.title"
      class="flex flex-col"
    >
      <h3 class="text-lg font-bold">
        {{ section.title }}
      </h3>
      <hr class="mb-4 mt-2 border-gray-300">
      <li
        v-for="field in filteredSortedFields(section.fields)"
        :key="field.label"
        class="mb-4 flex flex-col gap-y-2"
      >
        <span class="text-sm font-semibold">
          {{ field.label }}
        </span>
        <div class="rounded-lg border border-gray-200 bg-white px-4 py-2">
          {{ field.value }}
        </div>
      </li>
    </ol>
  </ol>
</template>
